export const columnDefsYieldRMHistorie = [
  {
    sortable: true,
    field: 'calendarWeek',
    headerName: 'KW',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    valueFormatter: params => 'KW' + params.value,
    autoGroupColumnDef: {
      headerName: 'KW',
    },
  },
  {
    sortable: true,
    field: 'rm',
    headerName: 'RM',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
    autoGroupColumnDef: {
      headerName: 'Name',
    },
  },
  {
    sortable: true,
    field: 'prioritaet',
    headerName: 'Prio',
    cellRenderer: 'CellRendererPrioritaet',
    enableRowGroup: true,
    rowGroup: true,
    hide: true,
  },
  { sortable: true, field: 'reise', headerName: 'Reise', width: 110, sort: 'asc', pinned: 'left' },
  { sortable: true, field: 'abfh', headerName: 'ABFH', width: 85, pinned: 'left' },
  {
    sortable: true,
    field: 'kpiVorBearbeitung',
    headerName: ' KPI vor Bearbeitung',
    width: 130,
    headerComponentParams: {
      withBreakpoints: true,
    },
    aggFunc: params => {
      let total = 0;
      params.values.forEach(value => (total += value));
      total = Math.round(total / params.values.length);
      return total;
    },
    valueFormatter: params => (!isNaN(params.value) ? params.value + '%' : null),
  },
  {
    sortable: true,
    field: 'kpiNachBearbeitung',
    headerName: 'KPI nach Bearbeitung',
    aggFunc: params => {
      let total = 0;
      params.values.forEach(value => (total += value));
      total = Math.round(total / params.values.length);
      return total;
    },
    headerComponentParams: {
      withBreakpoints: true,
    },
    valueFormatter: params => (!isNaN(params.value) ? params.value + '%' : null),
    width: 130,
  },
  { sortable: true, field: 'countNichtVerfuegbarHistory', aggFunc: 'sum', headerName: 'N', width: 70 },
  { sortable: true, field: 'countAufAnfrageHistory', aggFunc: 'sum', headerName: 'RQ', width: 70 },
  {
    sortable: true,
    field: 'countVerfuegbarGt200History',
    aggFunc: 'sum',
    headerName: 'J >= 200',
    width: 120,
  },
  {
    sortable: true,
    field: 'countAcceptedHistory',
    headerName: 'Akzeptiert',
    width: 135,
    aggFunc: params => {
      // if second level of grouping
      if (
        params.values.length > 0 &&
        params.values[0].hasOwnProperty('total') &&
        params.values[0].hasOwnProperty('totalAkzeptiert')
      ) {
        const totalAkzeptiert = params.values.reduce((acc, curr) => acc + curr.totalAkzeptiert, 0);
        const total = params.values.reduce((acc, curr) => acc + curr.total, 0);
        const percentAkzeptiert = Math.round((totalAkzeptiert / total) * 100);
        return {
          toString: () => totalAkzeptiert + ' (' + percentAkzeptiert + '%)',
          totalAkzeptiert,
          total,
        };
      }
      // if first level of grouping
      else {
        const totalAkzeptiert = params.values.reduce((acc, curr) => acc + curr.countAcceptedHistory, 0);
        const total = params.values.reduce(
          (acc, curr) =>
            acc +
            curr.countAufAnfrageHistory +
            curr.countNichtVerfuegbarHistory +
            curr.countVerfuegbarGt200History,
          0
        );
        const percentAkzeptiert = Math.round((totalAkzeptiert / total) * 100);
        return {
          toString: () => totalAkzeptiert + ' (' + percentAkzeptiert + '%)',
          totalAkzeptiert,
          total,
        };
      }
    },
    valueGetter: params => ({
      countNichtVerfuegbarHistory: params.data.countNichtVerfuegbarHistory,
      countAufAnfrageHistory: params.data.countAufAnfrageHistory,
      countVerfuegbarGt200History: params.data.countVerfuegbarGt200History,
      countAcceptedHistory: params.data.countAcceptedHistory,
    }),
    valueFormatter: params => {
      if (params.data) {
        const {
          countNichtVerfuegbarHistory,
          countAufAnfrageHistory,
          countVerfuegbarGt200History,
          countAcceptedHistory,
        } = params.data;
        const zuBearbeitenCount =
          countNichtVerfuegbarHistory + countAufAnfrageHistory + countVerfuegbarGt200History;
        if (zuBearbeitenCount === 0) return countAcceptedHistory + ' (0%)';
        const percentVerfGeschaffen = Math.round((countAcceptedHistory / zuBearbeitenCount) * 100);
        return countAcceptedHistory + ' (' + percentVerfGeschaffen + '%)';
      } else params.value;
    },
  },
  {
    sortable: true,
    field: 'countVerfuegbarkeitGeschaffenHistory',
    aggFunc: params => {
      // if second level of grouping
      if (
        params.values.length > 0 &&
        params.values[0].hasOwnProperty('total') &&
        params.values[0].hasOwnProperty('totalVerfGeschaffen')
      ) {
        const totalVerfGeschaffen = params.values.reduce((acc, curr) => acc + curr.totalVerfGeschaffen, 0);
        const total = params.values.reduce((acc, curr) => acc + curr.total, 0);
        const percentVerfGeschaffen = Math.round((totalVerfGeschaffen / total) * 100);
        return {
          toString: () => totalVerfGeschaffen + ' (' + percentVerfGeschaffen + '%)',
          totalVerfGeschaffen,
          total,
        };
      }
      // if first level of grouping
      else {
        const totalVerfGeschaffen = params.values.reduce(
          (acc, curr) => acc + curr.countVerfuegbarkeitGeschaffenHistory,
          0
        );
        const total = params.values.reduce(
          (acc, curr) =>
            acc +
            curr.countAufAnfrageHistory +
            curr.countNichtVerfuegbarHistory +
            curr.countVerfuegbarGt200History,
          0
        );
        const percentVerfGeschaffen = Math.round((totalVerfGeschaffen / total) * 100);
        return {
          toString: () => totalVerfGeschaffen + ' (' + percentVerfGeschaffen + '%)',
          totalVerfGeschaffen,
          total,
        };
      }
    },
    headerName: 'Verf. geschaffen',
    valueGetter: params => ({
      countNichtVerfuegbarHistory: params.data.countNichtVerfuegbarHistory,
      countAufAnfrageHistory: params.data.countAufAnfrageHistory,
      countVerfuegbarGt200History: params.data.countVerfuegbarGt200History,
      countVerfuegbarkeitGeschaffenHistory: params.data.countVerfuegbarkeitGeschaffenHistory,
    }),
    valueFormatter: params => {
      if (params.data) {
        const {
          countNichtVerfuegbarHistory,
          countAufAnfrageHistory,
          countVerfuegbarGt200History,
          countVerfuegbarkeitGeschaffenHistory,
        } = params.data;
        const zuBearbeitenCount =
          countNichtVerfuegbarHistory + countAufAnfrageHistory + countVerfuegbarGt200History;
        if (zuBearbeitenCount === 0) return countVerfuegbarkeitGeschaffenHistory + ' (0%)';
        const percentVerfGeschaffen = Math.round(
          (countVerfuegbarkeitGeschaffenHistory / zuBearbeitenCount) * 100
        );
        return countVerfuegbarkeitGeschaffenHistory + ' (' + percentVerfGeschaffen + '%)';
      } else params.value;
    },
    headerComponentParams: {
      withBreakpoints: true,
    },
    width: 145,
  },
  {
    sortable: true,
    field: 'countVerfuegbarkeitNichtGeschaffenHistory',
    aggFunc: 'sum',
    headerName: 'Verf. nicht geschaffen',
    headerComponentParams: {
      withBreakpoints: true,
    },
    width: 140,
  },
  {
    sortable: true,
    field: 'countNotEditedHistory',
    aggFunc: 'sum',
    headerName: 'Nicht bearbeitet',
    headerComponentParams: {
      withBreakpoints: true,
    },
    width: 140,
  },
  {
    sortable: true,
    field: 'countEditedHistory',
    aggFunc: 'sum',
    headerName: 'Bearbeitet',
    cellRenderer: params => '<b>' + params.value + '</b>',
    headerComponentParams: {
      bold: true,
    },
    width: 140,
  },
  {
    sortable: true,
    field: 'countGesamtHistory',
    aggFunc: 'sum',
    cellRenderer: params => '<b>' + params.value + '</b>',
    headerComponentParams: {
      bold: true,
    },
    headerName: 'Gesamt',
    width: 115,
  },
];
